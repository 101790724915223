import "owl.carousel"
import moment from 'moment'


jQuery(document).ready(function ($) {

  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $(".nav-list").toggleClass('active')
    $("#nav-social").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('.nav-list').hasClass('active')) {
      $(".nav-list").toggleClass('active')
      $("#nav-social").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })


// MUSIC CAROUSEL
function createMusicCarousel() {
  const musicCarousel = $("#music-carousel")
  const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
  const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

  musicCarousel.owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    items: 1,
    startPosition: 0,
    autoplayTimeout: 3000,
    autoplaySpeed: 500,
    margin: 55,
    center: true,
    responsive: {
      0: {

      },
      700: {

      }
    },
    navText: [
      leftArrow,
      rightArrow
    ]
  })
}
createMusicCarousel()

// VIDEO CAROUSEL
function createVideoCarousel() {
  const videoCarousel = $("#video-carousel")
  const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
  const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

  videoCarousel.owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    items: 1,
    startPosition: 0,
    autoplayTimeout: 3000,
    autoplaySpeed: 500,
    margin: 55,
    center: true,
    responsive: {
      0: {
        stagePadding: 100
      },
      700: {
        stagePadding: 300
      }
    },
    navText: [
      leftArrow,
      rightArrow
    ]
  })
}
createVideoCarousel()


// Tourdate Import
$.ajax({
  url: 'https://rest.bandsintown.com/artists/id_15536395/events?app_id=45PRESS_graham-barham',
  method: 'GET',
  dataType: 'json',
  error: () => {
    alert('Error fetching events!');
  }, 
  success: data => {
    const events = $('#tour-dates');
    let html = '';
    let n = 0
    if (data.length) {
      for (let event of data) {
        n++
        html += '<div class="event-group">';
        html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
        html += '<div class="event-venue-location">' + event.venue.name.toUpperCase() + " &#x2022; " + event.venue.location.toUpperCase() + '</div>';

        html += '<div class="event-links">';
        for (let offer of event.offers) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn link">' + offer.type.toUpperCase() + '</a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html(html);
    } else {
      events.html('<span class="no-events">Check back soon for new shows!</span>');
    }
    if (n<9) { 
      $("#toggle-dates").hide(); 
    }
  }
});

});